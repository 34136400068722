footer {
	.footerContainer {
		.questions {
			p {
				img.garantee {
					max-height: 60px;
				}
			}
		}
	}
}